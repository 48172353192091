import i18n from '@/i18n'

export default {
  name: 'NoDataAvailableComponent',
  components: {
  },
  props: {
    text: {
      type: String,
      required: false,
      default: () => { return i18n.t('noDataAvailable.text') }
    }
  },
  data: () => ({
  }),
  async mounted () {
  },
  created () {
  },
  beforeDestroy () {
  },
  computed: {
  },
  methods: {
  }
}
